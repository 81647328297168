import { Box, Text, Flex, Button, useToast } from "@chakra-ui/react";
import Modal from "../../../components/Modal/Modal";
import { useOnboardingStore } from "@olagg/store";
import { STEPS } from "../constants";
import { GoPasskeyFill } from "react-icons/go";
import useAuth from "../hooks/useAuth";
import { analytics } from "../../../analytics";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useContext } from "react";
import { useTranslation } from "react-i18next";

type IProps = {
  email: string,
}

const SetPasskey = ({ email }: IProps) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { t } = useTranslation();
  const { isOnboardingOpen, step, setStep, closeOnboarding } = useOnboardingStore()
  const { generateChallenge, validateRegistration, handleUserToken } = useAuth()

  const nextStep = () => setStep(STEPS.completeData)
  const toast = useToast()

  const handleLoginWithPasskey = async () => {
    const challenge = await generateChallenge(email)
    const { options } = challenge

    await validateRegistration(options)
      .then((response) => handleUserToken(response, 'passkey'))
      .then(() => [
        analytics()
          ?.track('User Passkey Set', {
            email,
          })
      ])
      .then(() => setStep(STEPS.completeData))
      .catch(() => {
        toast({
          title: t('Login.passKey.validateRegistration.error'),
          description: t('Login.passKey.validateRegistration.errorDescription'),
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <Modal isOpen={isOnboardingOpen && step === STEPS.setPasskey} onClose={closeOnboarding} size={{ base: 'xs', md: 'lg' }}>
      <Box p="8">
        <Text fontSize='26px' fontWeight='600' mb={4} color="#3F2091">{t('Login.passKey.createPassKey')}</Text>

        <Flex fontSize='16px' lineHeight='24px' fontWeight='800' gap={1} alignItems='center'>
          <GoPasskeyFill />
          <Text mt="3px">
            {t('Login.passKey.forgetRememberPassword')}
          </Text>
        </Flex>
        <Text mt={1} fontSize='16px' lineHeight='24px' fontWeight='500' textAlign={'justify'}>
          {t('Login.passKey.forgetRememberPasswordLead')}
        </Text>

        <Flex fontSize='16px' lineHeight='24px' fontWeight='800' gap={1} alignItems='center' mt={4}>
          <GoPasskeyFill />
          <Text mt="3px">
            {t('Login.passKey.devicesSync')}
          </Text>
        </Flex>
        <Text mt={1} fontSize='16px' lineHeight='24px' fontWeight='500' textAlign={'justify'}>
          {t('Login.passKey.devicesSyncLead')}
        </Text>

        <Flex fontSize='16px' lineHeight='24px' fontWeight='800' gap={1} alignItems='center' mt={4}>
          <GoPasskeyFill />
          <Text mt="3px">
            {t('Login.passKey.moreSecurity')}
          </Text>
        </Flex>
        <Text mt={1} fontSize='16px' lineHeight='24px' fontWeight='500' textAlign={'justify'}>
          {t('Login.passKey.moreSecurityLead')}
        </Text>

        <Flex justifyContent={'center'} mt={6} w="100%" direction={'column'} gap={4}>
          <Button onClick={handleLoginWithPasskey} variant="filledGradient" size="lg" w="100%" type="button">
            {t('Login.passKey.createPassKeyButton')}
          </Button>
          <Button onClick={nextStep} size="lg" w="100%" type="button" color="#3F2091" bgColor={'transparent'}>
            {t('Login.passKey.omitStep')}
          </Button>
        </Flex>

      </Box>
    </Modal >
  )
}

export default SetPasskey;
