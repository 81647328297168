import {
  ColorHues,
  extendTheme,
  theme as defaultTheme,
  Theme
} from '@chakra-ui/react'

// const OLAGGTheme: Theme = extendTheme({
//   components: {
//     Button: {
//       variants: {
//         outlinedGradient: {
//           background: 'none',
//           border: '2px solid',
//           borderImageSlice: 1,
//           borderWidth: '5px',
//           borderColor: 'transparent',
//           borderImageSource:
//             'linear-gradient(to left, #4E10FF, #AB17DF)',
//           borderRadius: theme['radii']['lg'],
//           color: 'white',
//           _hover: {
//             bg: 'gray.100',
//             color: 'gray.800'
//           }
//         }
//       }
//     }
//   }
// })

interface IRainbowTheme {
  accentColor: string
  borderRadius: 'large' | 'medium' | 'small' | 'none'
  overlayBlur: 'large' | 'small' | 'none'
  accentColorForeground: string
}

export const rainbowKitTheme = (
  isMobile: boolean
): IRainbowTheme => ({
  accentColor: isMobile
    ? 'white'
    : 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
  accentColorForeground: 'white',
  borderRadius: 'small',
  overlayBlur: 'small'
})

const makeColorscheme = (hex: string): Partial<ColorHues> => {
  return {
    50: hex,
    100: hex,
    200: hex,
    300: hex,
    400: hex,
    500: hex,
    600: hex,
    700: hex,
    800: hex,
    900: hex
  }
}

type IOLAGGTheme = Theme & {
  colors: {
    olaggBlue: ColorHues
    olaggPink: ColorHues
    olaggPurple: ColorHues
    olaggYellow: ColorHues
    olaggDark: ColorHues
    olaggGrey: ColorHues
  }
}

const theme: IOLAGGTheme = extendTheme(
  {
    baseStyle: {
      _focus: {
        boxShadow: 'none'
      }
    },
    fonts: {
      body: "'space grotesk', sans-serif",
      text: "'space grotesk', sans-serif"
    },
    colors: {
      olaggBlue: {
        ...makeColorscheme('#4E10FF')
      },
      olaggPink: {
        ...makeColorscheme('#AB17DF')
      },
      olaggPurple: {
        ...makeColorscheme('#3F2091')
      },
      olaggYellow: {
        ...makeColorscheme('#FEAD10')
      },
      olaggDark: {
        ...makeColorscheme('#0C0324')
      },
      olaggGrey: {
        ...makeColorscheme('#8c7c9b')
      }
    },
    components: {
      Button: {
        baseStyle: {
          px: 2,
          py: 6
        },
        variants: {
          outline: {
            borderRadius: '6px',
            color: 'white',
            _disabled: {
              pointerEvents: 'none'
            },
            _hover: {
              color: 'olaggPurple.200'
            }
          },
          outlinedGradient: {
            position: 'relative',
            color: 'white',
            '&::before': {
              borderRadius: '6px',
              border: '2px solid transparent',
              background:
                'linear-gradient(45deg, #4E10FF, #AB17DF) border-box',
              WebkitMask:
                'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              content: `""`,
              position: 'absolute',
              inset: 0
            }
          },
          filledGradient: {
            background:
              'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
            borderRadius: '6px',
            color: 'white',
            _disabled: {
              pointerEvents: 'none'
            }
          },
          questsDefault: {
            background: 'black',
            color: 'white',
            borderRadius: '6px',
            _disabled: {
              pointerEvents: 'none'
            },
            _hover: {
              background: 'gray.800'
            }
          }
        }
      },
      Divider: {
        variants: {
          solidGradient: {
            position: 'relative',
            color: 'white',
            '&::before': {
              borderStyle: 'solid',
              border: '1px solid transparent',
              background:
                'linear-gradient(45deg, #4E10FF, #AB17DF) border-box',
              WebkitMask:
                'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              content: `""`,
              position: 'absolute',
              inset: 0
            }
          }
        }
      },
      Text: {
        variants: {
          filledGradient: {
            background: 'linear-gradient(45deg, #4E10FF, #AB17DF) border-box',
            backgroundClip: 'text',
            color: 'transparent'
          }
        }
      }
    }
  },
  defaultTheme // optional
) as IOLAGGTheme

export { theme }
