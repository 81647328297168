import { Box, Flex, Progress, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import {Xp} from '@olagg/ui-kit/svg/Xp'
import {XpStatic} from '@olagg/ui-kit/svg/XpStatic'
import { getPercentage } from './data'
import s from './styles.module.css'
import { useAuthStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'

const Level = ({mode = 'nav'}) => {
    const { me } = useAuthStore()
    const [levelData, setLevelData] = useState(me?.level)
    const [percent, setPercentage] = useState(getPercentage(me?.level))
    const prevCountRef = useRef(me?.level?.score || 0)
    const { t } = useTranslation()
    
    useEffect(() => {
        setLevelData(me?.level)
        setPercentage(getPercentage(me?.level))
        showAnim()
    }, [me])
    
    const [levelAnim, setlevelAnim] = useState(false)

    const showAnim = () => {
        if(me?.level?.score > prevCountRef.current) {
            setTimeout(() => {
                setlevelAnim(true)
            }, 500)
            setTimeout(() => {
                setlevelAnim(false)
            }, 1001)
            prevCountRef.current = me?.level?.score;
        }
    }

    return (
        <Box width="100%">
            <Flex direction="row" align="center">
                <Flex color='white' fontWeight="bold" fontSize="16px" gap={mode == 'nav' ? 'inherit' : '8px'} alignItems={mode == 'nav' ? 'flex-start' : 'center'} flexDirection={mode == 'nav' ? 'column' : 'row'}>
                    <Text>{t('navbar.myAccountNav.level')} {levelData.value}</Text>
                    <Box display='flex'>
                      <Text position='relative' top='4px'>{me?.level.score || 0} </Text>
                      <Text w='32px'>
                        <Xp color='' transparentBackground/>
                      </Text>
                    </Box>
                </Flex>
                <Flex color='white' fontSize="16px" marginLeft="auto" fontWeight={500} textColor={'rgba(255, 255, 255, 0.36)'} gap={mode == 'nav' ? 'inherit' : '8px'} alignItems={mode == 'nav' ? 'flex-start' : 'center'} flexDirection={mode == 'nav' ? 'column' : 'row'}>
                  <Text>{t('navbar.myAccountNav.level')} {levelData.value + 1}</Text>
                  <Box display='flex' alignItems={'center'}>
                    <Text>{levelData.nextLevelScore}</Text>
                    <XpStatic color='rgba(255, 255, 255, 0.36)' width={'26px'} height={'26px'} />
                  </Box>
                </Flex>
            </Flex>
            <Progress className={levelAnim ? s.glowLevel: ''} onChange={showAnim} sx={{
                background: 'rgba(255, 255, 255, 0.16)',
                '& > div': {
                    background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                    transition: 'width 500ms'
                },
                }} rounded="10px" height="10px" value={percent} />
        </Box>
    )
}

export default Level
  