import {
  AuthEndpoints,
  fetcher,
  GameEndpoints,
  OpportunitiesEndpoints,
  QuestOwnerEndpoints,
  QuestEndpoints,
  MarketPlaceEndpoints,
  RolesEndpoints
} from '@olagg/api-hooks'
import type {
  DiscordAccount,
  GameCategory,
  GoogleAccount,
  TwitterAccount,
  User,
  QuestOwner,
  Quest,
  MarketPlaceItem,
  MarketPlaceStockItem,
  Role,
  RolePermission,
  JobOpportunity
} from '@olagg/db-types'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import Cookies from 'js-cookie';
import * as ctz from 'countries-and-timezones'
import bcrypt from 'bcryptjs';

enum KYC_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_FOUND = 'NOT_FOUND'
}

export interface AuthState {
  loggedIn: boolean
  me:
  | (User & {
    webauthn?: boolean | false
    discordAccount?: DiscordAccount | null
    googleAccount?: GoogleAccount | null
    authProviderData?: Record<string, TwitterAccount>
    metamapVerification?: {
      lastStatusReceived: KYC_STATUS | null
    } | null
  })
  | undefined
  getMe: () => Promise<any>
  check: () => void
  login: (provider: 'google' | 'discord') => void
  logout: () => void
  setMe: (data: User) => void
}

export interface OnboardingState {
  isOnboardingOpen: boolean
  step: string
  setStep: (step: string) => void
  openOnboarding: (forceDiscord?: boolean, step?: string) => void
  closeOnboarding: () => void
  isSignedIn: boolean
  isRedirected: boolean
  providerSignedIn: string
  setIsRedirected: (status: boolean) => void
  setIsSignedIn: (status: boolean, provider: string) => void,
  forceDiscord: boolean,
  setForceDiscord: (forceDiscord: boolean) => void
}

export interface OwnersState {
  ownersData: QuestOwner[]
  getOwners: () => Promise<any>
  getOwnersData: () => QuestOwner[]
}

export interface QuestsState {
  activeQuest: Quest | null
  questsData: Quest[]
  questsByOwnerIdData: any
  getQuestsByOwnerId: (ownerId: string) => Promise<any>
  getQuests: () => Promise<any>
  getQuestsData: () => Quest[]
  setActiveQuest: (quest: Quest | null) => void
}

export interface CategoriesState {
  categories: GameCategory[]
  getCategories: () => GameCategory[]
}

export interface OpportunitiesState {
  opportunities: JobOpportunity[]
  getOpportunities: () => JobOpportunity[]
}

export interface MarketPlaceState {
  marketPlaceData: MarketPlaceItem[]
  marketPlaceUserStockData: MarketPlaceStockItem[]
  marketPlaceItemsAvailable: () => MarketPlaceItem[]
  marketPlaceItemsUnavailable: () => MarketPlaceItem[]
  marketPlaceItemsAll: () => MarketPlaceItem[]
  getMarketPlaceItems: () => Promise<MarketPlaceItem[]>
  purchaseMarketPlaceItem: (id: string) => Promise<any>
  purchaseMarketPlaceItemWithCoupon: (id: string, coupon: string) => Promise<any>
  userStockData: () => MarketPlaceStockItem[]
}

export interface RolesState {
  roles: Role[]
  permissions: RolePermission[]
  initRoles: () => Promise<any>
  initPermissions: () => Promise<any>
}

export const useCategoriesStore = create<CategoriesState>()(
  persist(
    (set, get) => ({
      categories: [],
      getCategories: () => {
        const detachedFetchCategories = () => {
          fetcher(GameEndpoints.categories)
            .then(data => {
              set({ categories: data.categories })
            })
            .catch(_e => {
              set({
                categories: []
              })
            })
        }

        detachedFetchCategories() // fetch from db
        if (get().categories) return get().categories // return from localstorage
        return []
      }
    }),
    {
      name: 'ola-categories',
      getStorage: () => window.localStorage
    }
  )
)

export const useOpportunitiesStore = create<OpportunitiesState>()(
  persist(
    (set, get) => ({
      opportunities: [],
      getOpportunities: () => {
        const detachedFetchOpportunities = () => {
          fetcher(OpportunitiesEndpoints.all())
            .then(response => {
              set({ opportunities: response.opportunities })
            })
            .catch(_e => {
              set({
                opportunities: []
              })
            })
        }

        detachedFetchOpportunities() // fetch from db
        if (get().opportunities) return get().opportunities // return from localstorage
        return []
      }
    }),
    {
      name: 'ola-opportunities',
      getStorage: () => window.localStorage
    }
  )
)

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezoneData = ctz.getTimezone(tz)
export const tzCountry = timezoneData.countries[0]


export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      me: undefined,
      loggedIn: false,
      check: () => {
        fetcher<{ loggedIn: boolean }>(AuthEndpoints.check)
          .then(data => {
            set(state => ({
              ...state,
              loggedIn: data.loggedIn,
              me: data.loggedIn ? state.me : undefined
            }))
          })
          .catch(e => {
            set(state => ({
              ...state,
              loggedIn: false,
              me: undefined
            }))
          })
      },
      login: (provider: 'google' | 'discord') => {
        window.location.href = `${import.meta.env.VITE_API_URL || 'http://localhost:4000'
          }${AuthEndpoints.login[provider].path
          }?redirect=${encodeURIComponent(window.location.origin)}`
      },
      logout: () => {
        set(state => ({
          ...state,
          loggedIn: false,
          me: undefined
        }))

        Cookies.remove('auth._token.local', {
          domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
        })
      },
      getMe: async () => {
        await fetcher<{ user: User }>(AuthEndpoints.me)
          .then(({ user }) => {
            set(state => ({
              ...state,
              me: user,
              loggedIn: user ? true : false
            }))
          })
          .catch(() => {
            set(state => ({
              ...state,
              loggedIn: false,
              me: undefined
            }))
            Cookies.remove('auth._token.local', {
              domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
            })
          })
        return get().me ? get().me : undefined
      },
      setMe(data) {
        const updated = { ...get().me, ...data }

        set(state => ({
          ...state,
          me: updated
        }))
      }
    }),
    {
      name: 'ola-user',
      getStorage: () => window.localStorage
    }
  )
)

export const useOnboardingStore = create<OnboardingState>()(
  (set, get) => ({
    forceDiscord: false,
    isOnboardingOpen: false,
    step: 'none',
    setStep(step: string) {
      set(state => {
        return {
          ...state,
          step
        }
      })
    },
    openOnboarding(forceDiscord = false, step) {
      set(state => {
        return {
          ...state,
          forceDiscord,
          isOnboardingOpen: true,
          step: step || 'chooseMethod'
        }
      })
    },
    closeOnboarding() {
      set(state => ({
        ...state,
        forceDiscord: false,
        isOnboardingOpen: false,
        step: 'none'
      }))
    },
    isSignedIn: false,
    isRedirected: false,
    providerSignedIn: '',
    signedInWithDiscord() {
      return get().providerSignedIn === 'discord'
    },
    setIsSignedIn(status: boolean, provider: string) {
      set(state => {
        return {
          ...state,
          isSignedIn: status,
          providerSignedIn: provider
        }
      })
    },
    setIsRedirected(status: boolean) {
      set(state => {
        return {
          ...state,
          isRedirected: status
        }
      })
    },
    setForceDiscord(forceDiscord: boolean) {
      set(state => {
        return {
          ...state,
          forceDiscord
        }
      })
    },
  })
)

export interface ProfileSidebarState {
  isSidebarOpen: boolean
  toggleSidebar: () => void
}

export const useProfileSidebarStore = create<ProfileSidebarState>()(
  (set, get) => ({
    isSidebarOpen: false,
    toggleSidebar() {
      set(state => {
        return {
          ...state,
          isSidebarOpen: !state.isSidebarOpen
        }
      })
    }
  })
)

export const useOwnersStore = create<OwnersState>()(
  (set, get) => ({
    ownersData: [],
    getOwners: async () => {
      return await fetcher(QuestOwnerEndpoints.all())
        .then(response => {
          set(state => ({
            ...state,
            ownersData: response
          }))
        })
        .catch(_e => {
          set(state => ({
            ...state,
            ownersData: []
          }))
        })
    },
    getOwnersData: () => {
      return get().ownersData
    }
  })
)


export const useQuestsStore = create<QuestsState>()(
  (set, get) => ({
    activeQuest: null,
    questsByOwnerIdData: null,
    questsData: [],
    getQuestsByOwnerId: async (ownerId: string) => {
      return await fetcher(QuestEndpoints.byOwnerId(ownerId))
    },
    setActiveQuest: (quest: Quest | null) => {
      set(state => ({
        ...state,
        activeQuest: quest
      }))
    },
    getQuests: async () => {
      return await fetcher(QuestEndpoints.all())
        .then(response => {
          set(state => ({
            ...state,
            questsData: response
          }))
        })
        .catch(_e => {
          set(state => ({
            ...state,
            questsData: []
          }))
        })
    },
    getQuestsData: () => {
      return get().questsData
    }
  })
)

export const useMarketPlaceStore = create<MarketPlaceState>()(
  (set, get) => ({
    marketPlaceData: [],
    marketPlaceUserStockData: [],
    marketPlaceItemsAvailable: () => get().marketPlaceData.filter((item) => {
      let couponsStock = item?.couponsAvailable || 0
      if (item.status == 'UNAVAILABLE' && couponsStock > 0) return item
      return item.status != 'UNAVAILABLE' ? item : undefined
    }),
    marketPlaceItemsUnavailable: () => get().marketPlaceData.filter((item) => {
      let couponsStock = item?.couponsAvailable || 0
      return (item.status == 'UNAVAILABLE' && couponsStock == 0) ? item : undefined

    }),
    marketPlaceItemsAll: () => get().marketPlaceData,
    getMarketPlaceItems: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.all())
          set(state => ({
            ...state,
            marketPlaceData: response,
            marketPlaceUserStockData: response.userStock
          }))
          resolve(response)
        } catch (e) {
          set(state => ({
            ...state,
            marketPlaceData: []
          }))
          reject(e)
        }
      })
    },
    purchaseMarketPlaceItem: async (id: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.purchase(id))
          resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
    purchaseMarketPlaceItemWithCoupon: async (id: string, coupon: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.purchaseWithCoupon(id, coupon))
          resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
    userStockData: () => {
      return get().marketPlaceUserStockData
    }
  })
)

export const useRolesStore = create<RolesState>()(
  persist(
    (set, get) => ({
      roles: [],
      permissions: [],
      initRoles: async () => {
        await fetcher<{ roles: Role[] }>(RolesEndpoints.roles({ page: 1, limit: 1000 }))
          .then((response) => {
            set(state => ({
              ...state,
              roles: response.roles,
            }))
          })
          .catch(e => {
            set(state => ({
              ...state,
              roles: [],
            }))
          })
        return get().roles;
      },
      initPermissions: async () => {
        await fetcher<{ permissions: RolePermission[], meta: { count: number, hash: string } }>(RolesEndpoints.rolesPermissions({ page: 1, limit: 1000 }))
          .then((response) => {
            const permissionsSumProd = response.permissions.reduce((prev, curr) => prev + Number(curr.permission) * curr.role.id, 0);
            const rolesSalt = import.meta.env.VITE_ROLES_SALT;
            const validHash = bcrypt.hashSync(`${response.meta.count * permissionsSumProd}.${rolesSalt}`, '$2a$10$BMEBS4Nol0b7zuhrD1PBFe');
            if (response.meta.hash === validHash) {
              set(state => ({
                ...state,
                permissions: response.permissions,
              }));
            } else {
              set(state => ({
                ...state,
                permissions: [],
              }));
            }
          })
          .catch(e => {
            set(state => ({
              ...state,
              permissions: [],
            }))
          })
        return get().permissions;
      }
    }),
    {
      name: 'ola-roles',
      getStorage: () => window.localStorage
    }
  )
)
