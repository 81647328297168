
import { useEffect, useState } from "react";
import ChooseMethod from "./components/ChooseMethod";
import UpdateProfile from "./components/UpdateProfile";
import VerificationCode from "./components/VerificationCode";
import SetPasskey from "./components/SetPasskey";
import { useOnboardingStore } from "@olagg/store";
import { STEPS } from "./constants";
import LinkDiscord from "./components/LinkDiscord";
import { useSearchParams } from "react-router-dom";

const Authenticate = () => {
  const [email, setEmail] = useState('' as string)
  const [code, setCode] = useState<string>()
  const { step, setStep } = useOnboardingStore()

  const [searchParams] = useSearchParams()
  const verificationCode = searchParams.get('code')
  const verificationEmail = searchParams.get('verificationEmail')

  useEffect(() => {
    if (verificationCode && verificationEmail) {
      setEmail(verificationEmail)
      setCode(verificationCode)
      setStep(STEPS.verificationCode)
    }
  }, [])

  return (
    <>
      {step === STEPS.chooseMethod && < ChooseMethod email={email} changeEmail={setEmail} />}
      {step === STEPS.verificationCode && <VerificationCode email={email} verificationCode={code} />}
      {step === STEPS.setPasskey && <SetPasskey email={email} />}
      {step === STEPS.completeData && <UpdateProfile />}
      {step === STEPS.linkDiscord && <LinkDiscord />}
    </>
  )
}

export default Authenticate;
