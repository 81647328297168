export const XpStatic = ({
  width = '45',
  height = '20',
  color = '#fff',
  ...props
}: React.SVGProps<SVGSVGElement>) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 45 20" fill="none">
      <path d="M41.8831 1.75053C40.4273 0.589611 38.3556 0.00813256 35.6718 0.00813256H34.9033V0H21.4215C20.8157 0 20.2362 0.258209 19.8316 0.709566L15.8995 5.08285H25.7765L22.8752 19.5873L17.7294 10.0559L14.948 4.99746L12.5001 0.00813256H5.30281L10.3511 9.67571L0.933594 20H8.98686L13.3195 14.8562L15.8121 20H29.5297L30.5117 15.0879H33.9558C36.0114 15.0879 37.7965 14.7362 39.3111 14.0307C40.8258 13.3272 41.9969 12.3371 42.8244 11.0603C43.6519 9.7855 44.0667 8.30944 44.0667 6.63414C44.0667 4.54 43.3388 2.91146 41.881 1.75053H41.8831ZM36.4708 9.18979C35.9565 9.65742 35.2327 9.88919 34.2994 9.88919H31.5384L32.4737 5.20484H34.7284C35.5092 5.20484 36.1232 5.36139 36.5705 5.67653C37.0178 5.99166 37.2414 6.51012 37.2414 7.23391C37.2414 8.07157 36.9852 8.7242 36.4708 9.18979Z" fill={color}/>
    </svg>
  )
}

export default XpStatic
